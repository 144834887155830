<script setup lang="ts">
const { locale, t } = useI18n()

const colletions = [{
  title: t('homepage_navigation.mens_fashion'),
  image: '/categories_images/panska-moda.jpeg',
  link: '/collections/4725/panske-obleceni-a-doplnky',
}, {
  title: t('homepage_navigation.women_fashion'),
  image: '/categories_images/damska-moda.jpeg',
  link: '/collections/4921/damske-obleceni-a-doplnky',
}, {
  title: t('homepage_navigation.kids_fashion'),
  image: '/categories_images/detska-moda.jpeg',
  link: '/collections/5117/detske-obleceni-a-doplnky',
}, {
  title: t('homepage_navigation.baby_fashion'),
  image: '/categories_images/miminka.jpeg',
  link: '/collections/5420/miminko-a-batole',
}, {
  title: t('homepage_navigation.pets'),
  image: '/categories_images/pro-mazliky.jpeg',
  link: '/collections/4607/potreby-pro-domaci-mazlicky',
}, {
  title: t('homepage_navigation.electronics'),
  image: '/categories_images/elektronika.jpeg',
  link: '/collections/5801/elektronika',
}, {
  title: t('homepage_navigation.toys'),
  image: '/categories_images/hracky.jpeg',
  link: '/collections/8513/hracky-a-hry',
}, {
  title: t('homepage_navigation.home_garden'),
  image: '/categories_images/dum-a-zahrada.jpeg',
  link: '/collections/7091/dum-a-zahrada',
}, {
  title: t('homepage_navigation.health_beauty'),
  image: '/categories_images/zdravi-krasa.jpeg',
  link: '/collections/6896/zdravi-a-krasa',
}, {
  title: t('homepage_navigation.auto_moto'),
  image: '/categories_images/auto-moto.jpeg',
  link: '/collections/8680/vozidla-a-dily',
},
]
</script>

<template>
  <div v-if="colletions.length > 0" class="p-x-5 p-y-5">
    <div class="no-scrollbar w-full flex overflow-x-auto">
      <HomepageNavigationItem
        v-for="item in colletions" :key="item.title" :img-url="item.image" :link="item.link"
        :text="item.title"
      />
    </div>
  </div>
</template>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

definePageMeta({
  key: route => route.path,
})

const state = useStore()

const { t } = useI18n()

const { ecommerce } = useAnalytics()

const container = ref<HTMLElement | null>(null)
function scrollToContainer() {
  if (container.value == null)
    return

  container.value.scrollIntoView({ behavior: 'smooth' })
}

const page = useRouteQuery('page', '1', {
  transform: (v) => {
    const page = parseInt(v)
    return isNaN(page) ? 1 : page
  },
})

const drawers = useDrawers()

useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <HomepageHero />
  <HomepageNavigation />
  <main>
    <!-- Category section -->
  </main>

  <div ref="container" class="mx-auto max-w-3xl px4 pb8 lg:max-w-[88rem] lg:px8 md:max-w-4xl md:-m-t-2 sm:px6 sm:pb12">
    <div id="bestsellers" class="mx-auto max-w-3xl lg:max-w-[88rem] lg:px6 md:max-w-4xl sm:px4 sm:pb12 sm:pt2">
      <ProductGrid key="index" :page="page" :take="60" :sort="{ popularityScore: 'DESC' }" @update:page="page = $event" />
    </div>
  </div>
</template>
